import React, { useRef } from "react"
import Navbar from "../../Components/Navbar"
import NavbarMobile from "../../Components/NavbarMobile"
import emailjs from "@emailjs/browser"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./Form.scss"

export default function Contact() {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_fc5fmqr",
        "template_wkms8ns",
        form.current,
        "fWvIZIaviUtfexktP"
      )
      .then(
        (result) => {
          toast.success("Your Request is sent Succesfully!!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        },
        (error) => {
          toast.error(error.text, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
      )
  }

  return (
    <div className="page">
      <Navbar className="desktop" />
      <NavbarMobile className="mobile" />
      <div className="content">
        <ToastContainer />
        <h1>CONTACT</h1>
        <hr></hr>
        <div className="formContainer">
          <form ref={form} onSubmit={sendEmail} className="form">
            <input
              id="name"
              type="text"
              name="name"
              placeholder="Name"
              required
            />
            <br />
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              required
            />
            <br />
            <textarea
              id="msg"
              placeholder="Message"
              name="msg"
              rows="10"
              required
            ></textarea>
            <br />
            <button type="submit" className="bg">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
