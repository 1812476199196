import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import About from "./Pages/About"
import Projects from "./Pages/Projects"
import Resume from "./Pages/Resume/Resume"
import Education from "./Pages/Education/Education"
import Contact from "./Pages/Contact/Contact"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/education" element={<Education />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </Router>
)
