import React from "react"

export default function Loading() {
  return (
    <div className="container loading">
      <div className="loader">
        <div className="stripe small-stripe"></div>
        <div className="stripe medium-stripe"></div>
        <div className="stripe long-stripe"></div>
      </div>
    </div>
  )
}
