import React from "react"
import NavbarMobile from "../Components/NavbarMobile"
import Loading from "../Components/Loading"
import Navbar from "../Components/Navbar"

export default function About() {
  return (
    <div className="page">
      <Navbar />
      <NavbarMobile />
      <div className="content about">
        <h1>ABOUT</h1>
        <hr></hr>
        <Loading />
      </div>
    </div>
  )
}
