import React from "react"
import Navbar from "../../Components/Navbar"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import resume from "../../Assets/resume.pdf"
import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import NavbarMobile from "../../Components/NavbarMobile"
import "./resume.scss"

export default function Resume() {
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(resume).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob)
        // Setting various property values
        let alink = document.createElement("a")
        alink.href = fileURL
        alink.download = "01DushyanthResume.pdf"
        alink.click()
      })
    })
    // console.log("You clicked")
  }

  return (
    <div className="page">
      <Navbar className="desktop" />
      <NavbarMobile className="mobile" />
      <div className="content">
        <h1>RÉSUMÉ</h1>
        <hr></hr>
        <button className="btn download" onClick={onButtonClick}>
          DOWNLOAD
        </button>
        <div className="resumeholder">
          {/* <Document file={resume}>
            <Page pageNumber={1} className="desk" />
            <Page pageNumber={1} height={420} className="mob" />
          </Document> */}
          <iframe src={resume} width="100%" height="500px"></iframe>
        </div>
      </div>
    </div>
  )
}
