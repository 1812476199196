import React from "react";
import "./project.scss";

const Project = (props) => {
  return (
    <div className="project">
      <div className="info">
        <a href={props.link} target="_blank">
          <h5>{props.h}</h5>
        </a>
        <div className="mvp">
          {props.mvp && (
            <a href={props.mvp} target="_blank" className="mvp">
              <p>MVP 1</p>
            </a>
          )}
          {props.mvp2 && (
            <a href={props.mvp2} target="_blank" className="mvp">
              <p>MVP 2</p>
            </a>
          )}
        </div>
        <p>{props.p}</p>
      </div>
      {props.yt && (
        <div className="yt">
          <iframe
            src={props.yt}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      )}
      {props.pic1 && (
        <div className={`pic1 ${props.class}`}>
          <a href={props.link} target="_blank">
            <img src={props.pic1} alt="mob"></img>
          </a>
        </div>
      )}
      {props.pic2 && (
        <div className="pic2">
          <a href={props.link} target="_blank">
            <img src={props.pic2} alt="desk"></img>
          </a>
        </div>
      )}
      {props.pic3 && (
        <div className={`${props.class}`}>
          <a href={props.link} target="_blank">
            <img src={props.pic3} alt="mob"></img>
          </a>
        </div>
      )}
    </div>
  );
};

export default Project;
