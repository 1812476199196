import React from "react"

const EducationProfile = (props) => {
  return (
    <div className="eduProfile">
      <p className="para">{props.date}</p>
      <h2 className="head">{props.title}</h2>
      <p className="para">{props.subtitle}</p>
      <h2 className="hhead">{props.school}</h2>
    </div>
  )
}

export default EducationProfile
